import React, { Component } from 'react'
import Layout from '../components/layout/layout'
import CaseDetail from '../components/Cases/CaseDetail'
import Hero from '../components/Hero'
export default class ProductManagementCaseStudy extends Component {
  render() {
    const caseTopImage = require('../assets/images/rawpixel-574844-unsplash.jpg')
    const caseProblemImage = require('../assets/images/rawpixel-574844-unsplash.jpg')
    return (
      <Layout location={this.props.location}>
        <div className="gdlr-core-page-builder-body">
          <Hero
            title="Product Management "
            description="Finding the product-market fit"
          />
          <CaseDetail
            caseClient="Lightbulb education"
            caseTitle="Lightbulb education"
            caseDate="17 August 2017"
            caseTopImage={caseTopImage}
            caseProblemImage={caseProblemImage}
            caseHighlight={[
              'Redefined business model and value proposition',
              'Launched a Minimum viable product within 6 months',
              'Our work led to a 50% cost reduction in key input through consolidated local sourcing as a result of the market research conducted',
              'Enhanced branding plan with associated brand element',
              'A robust customer acquisition plan with clear monthly targets and revenue estimations',
            ]}
            caseDescription="Our client, a technology start-up was facing difficultly in gathering traction for
            their software services. "
            caseProblemDescription="The main challenges were poor business model, unclear
            customer acquisition plan, poorly scoped technology product and a weak brand
            asset. This led into inefficient operations and poor cash-flow"
            caseProblemDescriptionHighlight="Bora Growth Partners was hired to redesign, scope and assess a product-market
            fitness then reshape the business with appropriate technology product consistent
            with their target revenue projections."
            caseFeedback="We were able to rely on Bora Growth team in doing the work we did not have in-house support for. They managed to take our senior team to East Africa and this showed immediate results. KUDOS guys"
          />
        </div>
      </Layout>
    )
  }
}
